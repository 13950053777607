/* istanbul ignore file */

import {
  AuthProvider,
  ErrorBoundaryContextProvider,
  SettingsProvider,
  SigninSilentOidc,
  ValidatiePopup,
  withErrorBoundary,
  RouteEvents
} from "adviesbox-shared";
import React, { ReactElement, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import logo from "./assets/adviesbox-logo.svg";
import LicentieBeheerApp from "./licentiebeheer-app/LicentieBeheerApp";
import { RegistratieSuccesvol } from "./registreren/components/registratie-succesvol";
import { OrganisatieRegistreren } from "./registreren/organisatie-registreren";
import AutorisatieNiveauProvider from "./shared/autorisatie-niveau-context/autorisatie-niveau-provider";
import UserDetailsProvider from "./shared/user-details/user-details-provider";
import "./styles/style.scss";

const App = (): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const continueRef = useRef<any>(null);

  return (
    <div className="wrapper">
      <ValidatiePopup
        infotekst="browserInfo"
        show={modalShow}
        onHide={(): void => {
          setModalShow(false);
          continueRef.current(false);
        }}
        onSubmit={(): void => {
          setModalShow(false);
          if (continueRef.current) {
            continueRef.current(true);
          }
        }}
        navigatiepopup
      />

      <ErrorBoundaryContextProvider>
        <BrowserRouter
          getUserConfirmation={(message: string, callback: (ok: boolean) => void): void => {
            continueRef.current = callback;
            setModalShow(true);
          }}
        >
          <SettingsProvider loadingLogo={logo}>
            <Switch>
              <Route path="/organisatie-registreren" component={OrganisatieRegistreren} />
              <Route path="/account-activeren">
                <OrganisatieRegistreren startStep={4} />
              </Route>
              <Route path="/registratie-succesvol" component={RegistratieSuccesvol} />
              <Route path="/silent-redirect" component={withErrorBoundary(SigninSilentOidc)} />
              <Route
                render={(): ReactElement => (
                  <UserDetailsProvider>
                    <>
                      {/* //todo: please keep in place (disabled because of security finding: P105700-2-15): 
                          <GoogleAnalyticsTracker /> */}
                      <AuthProvider>
                        <RouteEvents>
                          <AutorisatieNiveauProvider>
                            <LicentieBeheerApp />
                          </AutorisatieNiveauProvider>
                        </RouteEvents>
                      </AuthProvider>
                    </>
                  </UserDetailsProvider>
                )}
              />
            </Switch>
          </SettingsProvider>
        </BrowserRouter>
      </ErrorBoundaryContextProvider>
    </div>
  );
};

App.displayName = "App";

export default withErrorBoundary(App);
